<div [ngStyle]="{
    display: (loaderService.showPageLoader | async) ? 'none' : 'block'
  }">
  <div class="bcl-main-work">
    <div class="bcl-first-screen-wrp">
      <div class="bcl-spec-call-details" *ngIf="showAdditionalInfo">
        <div class="spec-basic-info-card" *ngIf="showActiveTimeRange">
          <em class="bcl-icon"><img src="../../../assets/images/active-time-range.svg" alt=""></em>
          <div class="spec-call-info">
            <div class="call-title">Active time range
              <div class="bcl-tooltip-wrapper">
                <em class="tooltip-icon"><img src="../../../assets/images/bcl-tooltip-icon.svg" alt=""></em>
                <div class="bcl-tooltip-content">This indicates the time range on the selected date when the user is
                  available for bookings. Only slots within this range can be booked. This can be set up in the My
                  Accounts section.</div>
              </div>
            </div>
            <div class="call-content">
              <div class="content-list" *ngIf="!skeletonInfoLoaderVisible">
                <div class="inner-content" *ngFor="let slot of schedulerInfo?.activeTimeRange">
                  {{slot}}
                </div>
              </div>
            </div>
            <div class="skeleton-loader w-75" *ngIf="skeletonInfoLoaderVisible"></div>
          </div>
        </div>
        <div class="spec-basic-info-card">
          <em class="bcl-icon"><img src="../../../assets/images/slots-clock.svg" alt=""></em>
          <div class="spec-call-info">
            <div class="call-title">Slots can be booked from
              <div class="bcl-tooltip-wrapper">
                <em class="tooltip-icon"><img src="../../../assets/images/bcl-tooltip-icon.svg" alt=""></em>
                <div class="bcl-tooltip-content">After a specified buffer time following certain criteria, slots can be
                  booked. Slots can only be booked after this buffer period.</div>
              </div>
            </div>
            <div class="call-content">
              <div class="content-list" *ngIf="!skeletonInfoLoaderVisible">
                <div class="inner-content">{{schedulerInfo?.formattedSlotTime}}</div>
                <div class="inner-content">{{schedulerInfo?.buffer_time_in_hrs}} hr buffer</div>
              </div>
            </div>
            <div class="skeleton-loader w-75" *ngIf="skeletonInfoLoaderVisible"></div>
          </div>
        </div>
        <div class="spec-basic-info-card">
          <em class="bcl-icon"><img src="../../../assets/images/user-purple-rounded.svg" alt=""></em>
          <div class="spec-call-info">
            <div class="call-title">Minimum availability time
              <div class="bcl-tooltip-wrapper">
                <em class="tooltip-icon"><img src="../../../assets/images/bcl-tooltip-icon.svg" alt=""></em>
                <div class="bcl-tooltip-content">This indicates the minimum slot duration that must be free in user's
                  calendar to allow booking. For example, if the minimum availability time is set to 60 minutes, user
                  must have at least 60 minutes of free time on their calendar.</div>
              </div>
            </div>
            <div class="call-content">
              <div class="content-list" *ngIf="!skeletonInfoLoaderVisible">
                <div class="inner-content">{{schedulerInfo?.availability_time}} mins</div>
              </div>
            </div>
            <div class="skeleton-loader w-75" *ngIf="skeletonInfoLoaderVisible"></div>
          </div>
        </div>
        <div class="spec-basic-info-card">
          <em class="bcl-icon"><img src="../../../assets/images/note-icon.png" alt=""></em>
          <div class="spec-call-info">
            <div class="call-title">Note</div>
            <div class="call-content">
              <p>After each system-scheduled call, there's a 15-minute block time in which slots won’t be available.</p>
              <div class="note-thumb d-flex justify-content-between">
                <img src="../../../assets/images/note_start_slot.svg" alt="">
                <img src="../../../assets/images/note_end_slot.svg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bcl-first-screen" [ngClass]="{'full-width': !showAdditionalInfo}">
        <div class="bcl-main-heading bcl-common-heading">
          <div class="bcl-left-heading">Select Date & Time</div>
          <div class="bcl-right-heading" *ngIf="showAdditionalInfo">
            <div class="slots-availability">
              <span class="slot-title">Slots last updated</span>:
              <span class="time" *ngIf="!skeletonInfoLoaderVisible">{{schedulerInfo?.lastSlotUpdated}}</span>
              <span class="time skeleton-width skeleton-loader m-0" *ngIf="skeletonInfoLoaderVisible"></span>
            </div>
            <div class="slots-availability">
              <span class="slot-title">Slots next update</span>:
              <span class="time" *ngIf="!skeletonInfoLoaderVisible">{{schedulerInfo?.nextSlotUpdated}}</span>
              <span class="time skeleton-width skeleton-loader m-0" *ngIf="skeletonInfoLoaderVisible"></span>
            </div>
          </div>
        </div>
        <div class="bcl-main-calender-section">
          <div class="bcl-calender-wrp">
            <!-- calendar skeleton loader starts -->
            <div class="bcl-calender-skeleton" [ngClass]="{'d-none': !skeletonLoaderVisible}">
              <div class="ng-mydp">
                <div class="myDpSelector">
                  <div class="myDpMonthYearSelBar">
                    <div class="myDpPrevBtn">
                      <button type="button"
                        class="myDpHeaderBtn myDpIcon myDpIconLeftArrow myDpHeaderBtnEnabled"></button>
                    </div>
                    <div class="myDpMonthYearText">
                      <button type="button"
                        class="myDpHeaderBtn myDpMonthBtn myDpMonthLabel">{{showShimmerMonth}}</button>
                      <button type="button" class="myDpHeaderBtn myDpYearBtn myDpYearLabel">{{showShimmerYear}}</button>
                    </div>
                    <div class="myDpNextBtn">
                      <button type="button"
                        class="myDpHeaderBtn myDpIcon myDpIconRightArrow myDpHeaderBtnEnabled"></button>
                    </div>
                  </div>
                  <table class="myDpCalTable myDpNoFooter">
                    <thead>
                      <tr>
                        <th scope="col" class="myDpWeekDayTitle">Mon</th>
                        <th scope="col" class="myDpWeekDayTitle">Tue</th>
                        <th scope="col" class="myDpWeekDayTitle">Wed</th>
                        <th scope="col" class="myDpWeekDayTitle">Thu</th>
                        <th scope="col" class="myDpWeekDayTitle">Fri</th>
                        <th scope="col" class="myDpWeekDayTitle">Sat</th>
                        <th scope="col" class="myDpWeekDayTitle">Sun</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                      </tr>
                      <tr>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                      </tr>
                      <tr>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                      </tr>
                      <tr>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                      </tr>
                      <tr>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                        <td class="myDpDaycell myDpDisabled">
                          <div class="skeleton-loader h-100"></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- calendar skeleton loader ends -->
            <div [ngClass]="{'d-none': skeletonLoaderVisible}">
              <input angular-mydatepicker (calendarViewChanged)="calendarChange($event)" class="datePicker"
                type="hidden" [(ngModel)]="modelDate" (dateChanged)="onDateChange($event)"
                [options]="myDatePickerOptions" #dp="angular-mydatepicker" />
            </div>
          </div>
          <div class="bcl-calender-time-slot">
            <div class="bcl-common-sub-heading-wrp bcl-left-right-pad">
              <div class="bcl-common-subheading bcl-time-slog-timehead skeleton-loader w-50"
                *ngIf="skeletonLoaderVisible"></div>
              <div class="bcl-common-subheading bcl-time-slog-timehead" *ngIf="!skeletonLoaderVisible">
                {{
                (selectedDateShow | lowercase) != "invalid date"
                ? selectedDateShow
                : ""
                }}
              </div>
              <div class="bcl-slot-timezone">
                <!--<select [(ngModel)]="selectedTimezone" (change)="timezoneChange($event)" >
                                  <option [value]="timezone.abbr"  [selected]="timezone.abbr == 'IST'" *ngFor="let timezone of timezones ">{{timezone.abbr}}</option>
                              </select>-->
                <div class="bcl-timezone-dropdown">
                  <div class="custom-select" (click)="
                      selectTimezoneDropDown = !selectTimezoneDropDown;
                      searchTimeZone = ''
                    ">
                    {{ selectedTimezone }}
                  </div>
                  <div class="bcl-timezone-dropdown-wrp" *ngIf="selectTimezoneDropDown">
                    <div class="search-input">
                      <input [(ngModel)]="searchTimeZone" type="text" autofocus />
                    </div>
                    <ul *ngIf="timezones | filter : searchTimeZone as timezonesList">
                      <li class="disabled" *ngIf="timezonesList.length == 0">
                        No Records Found
                      </li>
                      <li *ngFor="let timezone of timezonesList" (click)="
                          selectedTimezone = timezone;
                          selectTimezoneDropDown = !selectTimezoneDropDown;
                          timezoneChange($event)
                        " [ngClass]="{ active: selectedTimezone === timezone }" title="{{ timezone }}">
                        {{ timezone }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- skeleton loader starts -->
            <div class="bcl-slot-book" *ngIf="skeletonLoaderVisible">
              <ul>
                <li class="slot"><span class="slot-time skeleton-loader m-0 border-0"></span></li>
                <li class="slot"><span class="slot-time skeleton-loader m-0 border-0"></span></li>
                <li class="slot"><span class="slot-time skeleton-loader m-0 border-0"></span></li>
                <li class="slot"><span class="slot-time skeleton-loader m-0 border-0"></span></li>
                <li class="slot"><span class="slot-time skeleton-loader m-0 border-0"></span></li>
                <li class="slot"><span class="slot-time skeleton-loader m-0 border-0"></span></li>
                <li class="slot"><span class="slot-time skeleton-loader m-0 border-0"></span></li>
                <li class="slot"><span class="slot-time skeleton-loader m-0 border-0"></span></li>
                <li class="slot"><span class="slot-time skeleton-loader m-0 border-0"></span></li>
                <li class="slot"><span class="slot-time skeleton-loader m-0 border-0"></span></li>
                <li class="slot"><span class="slot-time skeleton-loader m-0 border-0"></span></li>
                <li class="slot"><span class="slot-time skeleton-loader m-0 border-0"></span></li>
              </ul>
            </div>
            <!-- skeleton loader ends -->
            <ng-container *ngIf="!skeletonLoaderVisible">
              <div #slotsWindow class="bcl-slot-book" *ngIf="slots.length > 0">
                <ul>
                  <li class="slot tooltip-container" *ngFor="let slot of slotsInOfficeHours">
                    <span class="slot-time" [ngClass]="{
                        'bcl-active':
                          slot.start_time == selectedTimeSlot?.start_time,
                        'disabled-slot' : slot.disabled
                      }" (click)="selectTimeSlot(slot)">{{ timezoneConversion(slot.start_time) }}
                    </span>
                    <div *ngIf="slot.disabled" class="installmentContentTooltip">
                      <div class="instalment-content-inner">
                        Slot taken -
                        Recently booked by another user
                      </div>
                    </div>
                  </li>

                  <li *ngIf="slotsOutOfficeHours.length > 0 && !isOutOfficeSlotActive" class="text"
                    [ngClass]="{ active: isOutOfficeSlotActive }">
                    <ng-container *ngIf="slotsInOfficeHours.length > 0; else emptyInOfficeMsg">
                      <div class="slot-message">
                        <span class="or-text">OR</span>
                        <p>
                          You can choose these times (if early or later suits you
                          better)
                        </p>
                        <a href="javascript:void(0)" class="bordered-btn" (click)="
                            isOutOfficeSlotActive = true; scrollWindowSlide()
                          ">Show all available slots</a>
                      </div>
                    </ng-container>

                    <ng-template #emptyInOfficeMsg>
                      <p>
                        We only have these slots left (if early or later works for
                        you?):
                      </p>
                      <p>
                        <a href="javascript:void(0)" (click)="
                            isOutOfficeSlotActive = true; scrollWindowSlide()
                          ">Click here</a>
                        to view them.
                      </p>
                    </ng-template>
                  </li>
                  <li *ngIf="slotsInOfficeHours.length > 0 && isOutOfficeSlotActive" class="separator"></li>

                  <ng-container *ngIf="isOutOfficeSlotActive">
                    <li class="slot tooltip-container" *ngFor="let slot of slotsOutOfficeHours">
                      <span class="slot-time" [ngClass]="{
                          'bcl-active':
                            slot.start_time == selectedTimeSlot?.start_time,
                            'disabled-slot' : slot.disabled
                        }" (click)="selectTimeSlot(slot)">{{ timezoneConversion(slot.start_time) }}</span>
                      <div *ngIf="slot.disabled" class="installmentContentTooltip">
                        <div class="instalment-content-inner">
                          Slot taken -
                          Recently booked by another user
                        </div>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>
              <div class="no-slots" *ngIf="slots.length == 0">
                <em class="no-slot-icon"><img src="../../../assets/images/bcl-no-slots.svg" alt="" /></em>
                <p>No slots available for selected date.</p>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bcl-common-btn-group on-mobile-view-btn-grp" *ngIf="!hideButtons">
    <div class="error-warning on-mobile-warning">
      <div *ngIf="validError">Please select a time slot.</div>
      <div *ngIf="uniqueError">
        {{ uniqueError }}
      </div>
      <div *ngIf="chooseAnotherSlot && !uniqueError">
        Sorry, someone else has just snapped up that slot – can you pick another
        one?
      </div>
    </div>
    <div class="bcl-btn-grp for-mobile-only-btn">
      <button (click)="close()" class="bcl-common-button bcl-grey-btn">
        Reset</button><!--for second screen "Back"--><!--for third screen not visible-->
      <button (click)="confirmAvailability()" class="bcl-common-button bcl-green-btn bcl-confirm-btn">
        Confirm</button><!--for second screen "Schedule"--><!--for third screen "Resend Invite"-->
    </div>
  </div>
</div>

<!-- Confirm modal start-->
<div bsModal #rescheduleCallModal="bs-modal" class="client-modal rescheduleCallModal modal fade"
  id="rescheduleCallModal" tabindex="-1" role="dialog" aria-labelledby="modalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content transparent-modal bcl-schedule-box">
      <div class="modal-body">
        <div class="delete-task-block">
          <h4>Reschedule Call</h4>
          <p>Do you want to reschedule this call ?</p>
        </div>
        <div class="form-group btn-block d-flex justify-content-end bcl-common-btn-group">
          <button class="bcl-common-button bcl-grey-btn" type="button" (click)="hideReschduleCallModal()">
            No
          </button>
          <button class="bcl-common-button bcl-green-btn bcl-confirm-btn ml-2" type="button"
            (click)="confirmReschduleCallModal()">
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Confirm modal ends-->